import { render, staticRenderFns } from "./TextAddressComponent.vue?vue&type=template&id=d5f53224&scoped=true&"
import script from "./TextAddressComponent.vue?vue&type=script&lang=js&"
export * from "./TextAddressComponent.vue?vue&type=script&lang=js&"
import style0 from "./TextAddressComponent.vue?vue&type=style&index=0&id=d5f53224&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../usr/lib/node_modules/@vue/cli-service-global/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5f53224",
  null
  
)

export default component.exports