import { render, staticRenderFns } from "./ImageInfoComponent.vue?vue&type=template&id=ba912298&scoped=true&"
import script from "./ImageInfoComponent.vue?vue&type=script&lang=js&"
export * from "./ImageInfoComponent.vue?vue&type=script&lang=js&"
import style0 from "./ImageInfoComponent.vue?vue&type=style&index=0&id=ba912298&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../usr/lib/node_modules/@vue/cli-service-global/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba912298",
  null
  
)

export default component.exports